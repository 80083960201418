<template>
  <moe-page title="订单管理" class="orderPage">
    <!-- 筛选信息 -->
    <moe-inquire @search="orderSearch">
      <el-form-item label="订单编号">
        <el-input v-model.trim="orderParams.orderNo" placeholder="输入订单编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="支付方式">
        <moe-select v-model="orderParams.payType" type="payType" placeholder="支付方式" />
      </el-form-item>
      <el-form-item label="订单状态">
        <moe-select v-model="orderParams.payStatus" type="payStatus" placeholder="订单状态" />
      </el-form-item>

      <el-form-item label="用户编号">
        <el-input :value="orderParams.userId" @input="(value) => orderParams.userId = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入用户编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model.trim="orderParams.name" placeholder="输入用户名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="用户手机号">
        <el-input v-model.trim="orderParams.phone" placeholder="输入用户手机号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="收件人手机号">
        <el-input v-model.trim="orderParams.mobile" placeholder="输入收件人手机号" maxlength="100" clearable />
      </el-form-item>

      <el-form-item label="商品名称">
        <el-input v-model.trim="orderParams.goodsName" placeholder="输入商品名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="商品编号">
        <el-input :value="orderParams.goodsId" @input="(value) => orderParams.goodsId = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入商品编号" maxlength="100" clearable />
      </el-form-item>

      <el-form-item label="创建时间">
        <el-date-picker
          placement="bottom-start"
          v-model="dateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([orderParams.createTimeStart, orderParams.createTimeEnd] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <!-- 商品信息 -->
    <moe-table ref="orderTable" url="/shop/order/queryShopOrderList" :params="orderParams" :number-show="false" class="content">
      <el-table-column type="selection" width="55" />
      <el-table-column width="1" class="box">
        <template slot-scope="{ row }">
          <div class="orderNum df tal">
            <span class="ml-70">订单编号：{{ row.orderNo }}</span>
            <span class="ml-30">创建时间：{{ $moe_time.getTimeStamp(row.createTime) }}</span>
            <span class="ml-30"><moe-icon name="avatar" class="font-16"></moe-icon></span>
            <span class="ml-5">{{ row.name }}</span>
            <span class="ml-30 df aic"><i class="font-20 el-icon-phone"></i>{{ row.phone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品信息" min-width="300">
        <template slot-scope="{ row }">
          <div v-for="(item, index) in row.orderItemList" :key="index" class="df aic jcsb pt-10 pb-10">
            <div class="df">
              <moe-image :src="item.coverUrl" class="imgSty mr-10"/>
              <div class="tal df1">
                <p class="fwb">{{ item.goodsName }}</p>
                <div class="df aic">
                  <p class="font-12 color-info">{{ item.goodsItemValue }}</p>
                  <p v-if="item.fromGift" class="b-primary color-primary w-35 h-25 lh-25 tac ml-10">赠品</p>
                </div>
                <p class="color-danger"><span class="fwb">￥{{ item.payPrice }}</span> <span class="ml-10 color-info"> x {{ item.number }}</span></p>
              </div>
            </div>
            <div v-if="['AFTER_SALES_SERVICE'].includes(item.payStatus)">
              <p>
                <el-button type="success" size="mini" plain @click="$router.push(`/refundOrder/detail?itemOrderNo=${item.belowOrderNo}`)">请处理售后</el-button>
              </p>
            </div>
          </div>

        </template>
      </el-table-column>

      <el-table-column label="总数量" min-width="80" :formatter="allNumber" />

      <el-table-column label="支付方式" min-width="150">
        <template slot-scope="{ row }">
          <!-- 预售 -->
          <div class="df fdc aic jcfs" v-if="IS_PRESALE(row)">
            <div>
              <div :class="{ 'color-danger': IS_PRESALE_DEPOSIT(row) }">{{ depositComputed(row) }}</div>
              <div v-if="row.depositPayType">
                <div :class="$moe_format.getpayType(row.depositPayType).color">
                  <moe-icon :name="$moe_format.getpayType(row.depositPayType).icon" class="mr-5"></moe-icon>
                  <span>{{ $moe_format.getpayType(row.depositPayType).title }}</span>
                </div>
              </div>
            </div>

            <div class="mt-10" v-if="row.orderItemList.filter(({ isPayDeposit }) => isPayDeposit).length">
              <div :class="{ 'color-danger': IS_PRESALE_BALANCE(row) }">{{ balanceComputed(row) }}</div>
              <div v-if="row.orderItemList.filter(({ isPayBalance }) => isPayBalance).length">
                <div :class="$moe_format.getpayType(row.payType).color">
                  <moe-icon :name="$moe_format.getpayType(row.payType).icon" class="mr-5"></moe-icon>
                  <span>{{ $moe_format.getpayType(row.payType).title }}</span>
                </div>
              </div>
              <div v-else>-</div>
            </div>
          </div>
          <!-- 普通订单 -->
          <template v-else>
            <div>￥{{ row.payPrice }}</div>
            <div :class="$moe_format.getpayType(row.payType).color">
              <moe-icon :name="$moe_format.getpayType(row.payType).icon" class="mr-5"></moe-icon>
              <span>{{ $moe_format.getpayType(row.payType).title }}</span>
            </div>
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="payStatus" label="订单状态" min-width="100" :formatter="payStatusComputed" />

      <el-table-column label="操作" width="300">
        <div class="moe-table__btns" slot-scope="{ row }">
          <!-- v-if="['PENDING_SHIPMENT', 'PARTIAL_SHIPMENT'].includes(row.payStatus)" -->
          <el-button v-if="row.orderItemList.filter(({ canDeliver }) => canDeliver).length ? true : false" type="warning" icon="el-icon-box" size="mini" @click="$moe_coordinator.navigateTo('/order/shipment', { orderNo: row.orderNo })">发货</el-button>
          <el-button v-if="['PENDING_RECEIPT'].includes(row.payStatus)" type="primary" icon="el-icon-map-location" size="mini" @click="$moe_coordinator.navigateTo('/order/detail', { orderNo: row.orderNo, tabsValue: '1' })">查看物流</el-button>
          <el-button type="success" icon="el-icon-view" size="mini" @click="$moe_coordinator.navigateTo('/order/detail', { orderNo: row.orderNo })">查看详情</el-button>
          <el-button v-if="IS_PRESALE_REFUSE_DEPOSIT(row)" type="danger" size="mini" icon="el-icon-thumb" @click="handleRefuseDeposit(row)">退定金</el-button>
          <el-button v-if="IS_PENDING_PAYMENT(row)" type="danger" size="mini" icon="el-icon-thumb" @click="handleOrderClose(row)">关闭订单</el-button>
        </div>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" width="30%" title="系统提示" @close="handleDialogClose()" :autoHeight="true">
      <moe-form ref="refuseDepositRef" class="default-form" :showBack="false" :model="refuseDepositParams">
        <div class="font-16 mb-20">根据《预售协议》，因用户原因导致未付尾款不可退还定金。如要还定金，请备注退款原因。</div>
        <el-form-item prop="remark" :rules="rules.remark">
          <moe-textarea placeholder="请输入备注" :autosize="{ minRows: 4, maxRows: 6 }" v-model="refuseDepositParams.remark"></moe-textarea>
        </el-form-item>
      </moe-form>
      <template slot="footer">
        <el-button @click="handleDialogClose()">暂不退还定金</el-button>
        <el-button type="primary" @click="refuseDeposit()">{{ refuseDepositLoading ? '请稍候' : '确定退还定金' }}</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'goodsList',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      orderParams: {
        pageNum: 1,
        pageSize: 10,
        orderNo: '',
        payType: '',
        payStatus: '',
        userId: '',
        name: '',
        phone: '',
        mobile: '',
        goodsName: '',
        goodsId: '',
        createTimeStart: '',
        createTimeEnd: '',
      },
      dateTime: [],
      showDialog: false,
      refuseDepositLoading: false,
      refuseDepositParams: {
        orderNo: '',
        remark: ''
      },
      rules: {
        remark: verify.isEmpty('请输入备注'),
      }
    };
  },
  methods: {
    /**
     * 商品搜索
     **/
    orderSearch(isSearch) {
      if (!isSearch) {
        this.orderParams = {
          pageNum: 1,
          pageSize: 10,
          orderNo: '',
          payType: '',
          payStatus: '',
          userId: '',
          name: '',
          phone: '',
          mobile: '',
          goodsName: '',
          goodsId: '',
          createTimeStart: '',
          createTimeEnd: '',
        };

        this.dateTime = [];
      }

      //刷新商品表格
      this.$refs.orderTable.searchData();
    },
    /** 是否为预售订单 */
    IS_PRESALE({ orderItemList }) {
      if (orderItemList.filter(({ deposit }) => this.$moe_math.mathGreaterThan(deposit, 0)).length) {
        return true
      } else {
        return false
      }
    },
    /** 商品总数量 */
    allNumber({ orderItemList }) {
      let allNumber = 0
      orderItemList.forEach(({ number }) => {
        allNumber = this.$moe_math.mathAdd(allNumber, number);
      })
      return allNumber
    },
    /** 定金规格 */
    depositComputed({ payStatus, orderItemList }) {
      let depositMoney = 0
      orderItemList.forEach(({ deposit, number }) => {
        depositMoney = this.$moe_math.mathAdd(depositMoney, this.$moe_math.mathMul(deposit, number))
      })
      if (['ORDER_CLOSED', 'PENDING_PAYMENT'].includes(payStatus)) {
        if (orderItemList.filter(({ isPayDeposit }) => isPayDeposit).length) {
          return `定金已支付 ¥ ${depositMoney}`
        } else if (orderItemList.filter(({ isPayDeposit }) => !isPayDeposit).length) {
          return `定金待支付 ¥ ${depositMoney}`
        }
      } else {
        return `定金已支付 ¥ ${depositMoney}`
      }
    },
    /** 定金是否已付 */
    IS_PRESALE_DEPOSIT({ payStatus, orderItemList }) {
      // ['PENDING_PAYMENT'].includes(payStatus) &&
      if (orderItemList.filter(({ isPayDeposit }) => isPayDeposit).length) {
        return false
      } else {
        return true
      }
    },
    /** 尾款规则 */
    balanceComputed({ payStatus, orderItemList }) {
      let balanceMoney = 0
      orderItemList.forEach(({ payPrice, deposit, number }) => {
        if (this.$moe_math.mathGreaterThan(payPrice, 0)) {
          balanceMoney = this.$moe_math.mathSub(this.$moe_math.mathMul(payPrice, number), this.$moe_math.mathMul(deposit, number))
        }
      })

      if (['ORDER_CLOSED', 'PENDING_PAYMENT'].includes(payStatus)) {
        if (orderItemList.filter(({ isPayBalance }) => isPayBalance).length) {
          return `尾款已支付 ¥ ${balanceMoney}`
        } else if (orderItemList.filter(({ isPayBalance }) => !isPayBalance).length) {
          return `尾款待支付 ¥ ${balanceMoney}`
        }
      } else {
        return `尾款已支付 ¥ ${balanceMoney}`
      }
    },
    /** 尾款是否已付 */
    IS_PRESALE_BALANCE({ payStatus, orderItemList }) {
      // ['PENDING_PAYMENT'].includes(payStatus) &&
      if (orderItemList.filter(({ isPayBalance }) => isPayBalance).length) {
        return false
      } else {
        return true
      }
    },
    /** 是否展示退定金按钮 */
    IS_PRESALE_REFUSE_DEPOSIT({ payStatus, orderItemList }) {
      if (['PENDING_PAYMENT', 'ORDER_CLOSED'].includes(payStatus) && orderItemList.filter(({ isPayDeposit, isPayBalance, payStatus }) => isPayDeposit && !isPayBalance && !['REFUNDED'].includes(payStatus)).length) {
        return true
      } else {
        return false
      }
    },
    /** 是否展示关闭订单按钮 */
    IS_PENDING_PAYMENT({ payStatus, orderItemList }) {
      if (['PENDING_PAYMENT'].includes(payStatus) && !orderItemList.filter(({ isPayDeposit }) => isPayDeposit).length) {
        return true
      } else {
        return false
      }
    },
    /** 订单状态 */
    payStatusComputed({ payStatus, orderItemList }) {
      if (['PENDING_PAYMENT'].includes(payStatus)) {
        if (orderItemList.filter(({ deposit, isPayDeposit }) => this.$moe_math.mathGreaterThan(deposit, 0) && !isPayDeposit).length) {
          return `定金待支付`
        } else if (orderItemList.filter(({ deposit, isPayBalance }) => this.$moe_math.mathGreaterThan(deposit, 0) && !isPayBalance).length) {
          return `尾款待支付`
        } else {
          return `待付款`
        }
      } else {
        return this.$moe_format.getPayStatus(payStatus);
      }
    },
    handleRefuseDeposit({ orderNo }) {
      this.refuseDepositParams.orderNo = orderNo;
      this.showDialog = true;
    },
    handleDialogClose() {
      this.showDialog = false;
      this.$refs['refuseDepositRef'].resetFields();
    },
    /** 定金退还 */
    refuseDeposit() {
      this.$refs['refuseDepositRef'].validate(() => {
        this.refuseDepositLoading = true;
        this.$moe_api.ORDER_API.refuseDeposit(this.refuseDepositParams).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('定金退还成功', {
              completionHandler: () => {
                this.handleDialogClose();

                this.$refs.orderTable.searchData();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.refuseDepositLoading = false;
        });
      })
    },
    /** 待付款状态下关闭订单 */
    handleOrderClose({ orderNo, id }) {
      this.$moe_layer.confirm(`您确定要关闭订单"${orderNo}"吗？`, () => {
        this.$moe_api.ORDER_API.orderClose({ id }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('关闭订单成功', {
              completionHandler: () => {
                this.$refs.orderTable.searchData();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  },
};
</script>
<style lang="scss">
.orderPage{
  .el-table--fit{
    border-bottom: 1px solid #EBEEF5;
  }
  .orderNum {
    width: 100vw;
    background: rgba(223, 166, 99, 0.05);
    position: absolute;
    top: 0;
    left: -55px;
    z-index: 1;
    height: 50px;
    line-height: 50px;
  }

  .imgSty {
    width: 78px;
    display: block;
  }

  .mesStyTac {
    justify-content: center;
  }
  .content{
    .el-table--medium {
      .el-table__cell {
        padding-top: 70px;
        border-bottom: 0;
      }
    }
    .moe-table__head {
      .el-table__cell {
        padding: 10px 0;
      }
    }
    .el-table__row td:nth-child(1) .cell {
      position: absolute !important;
      top: 12px !important;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
  }
}
</style>
